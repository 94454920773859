.App {
  font-family: sans-serif;
}
.tekst {
  font-size: 128px;
}

html, body {
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}